@import "src/styles/colors";

body {
  background: linear-gradient(to right, $dark-blue-color, $light-blue-color);
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  line-height: 1.5;
}
